import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import remove from 'lodash/remove'
import orderBy from 'lodash/orderBy'
import { reactive, computed } from '@vue/composition-api'

export default (ctx) => {
  const {
    root: { $store },
  } = ctx

  const initialData = reactive({
    id: null,
    display_name: '',
    role: null,
    group_id: [],
    is_active: true,
    setting: {
      admin: {
        set_seo_project: true,
        noti_project_update: true,
        noti_promotion_create: true,
        noti_promotion_pending_summary: true,
      },
      user: {
        noti_project_create: true,
        noti_project_update: true,
        noti_promotion_approve: true
      }
    }
  })

  const formData = reactive({
    id: null,
    display_name: '',
    role: null,
    group_id: [],
    is_active: true,
    setting: {
      admin: {},
      user: {}
    }
  })

  const groups = computed(() => orderBy($store.state.users.groups, ['group_name']))
  const roles = computed(() => {
    const roles = $store.state.users.roles
    
    if (!ctx.root.$acl.check('super_editor')) {
      remove(roles, (role) => {
        return role.role === 'super_editor'
      })
    }
    return roles.map((role) => {
      let title = role.role
      switch (role.role) {
        case "editor":
          title = 'admin'
          break
        case "super_editor":
          title = 'super_admin'
          break
        default: title = role.role
      }
      return {
        title,
        ...role
      }
    })
  })

  const prepareFormData = (result) => {
    formData.id = result.id
    formData.display_name = result.display_name
    formData.setting = Object.assign({}, result.setting)
    formData.email = get(result, 'accounts.0.email', null)
    formData.role = get(result, 'accounts.0.account_roles.0.role', null)

    if (formData.role === 'super_editor' || formData.role === 'editor') {
      if (isEmpty(formData.setting.admin)) {
        formData.setting.admin = {
          set_seo_project: false,
          noti_project_update: false,
          noti_promotion_create: false,
          noti_promotion_pending_summary: false,
        }
      }
    } else if (formData.role === 'sale' || formData.role === 'marketing') {
      if (isEmpty(formData.setting.user)) {
        formData.setting.user = {
          noti_project_create: false,
          noti_project_update: false,
          noti_promotion_approve: false
        }
      }
    }

    if (!formData.setting.admin) {
      formData.setting.admin = {}
    }

    if (!formData.setting.user) {
      formData.setting.user = {}
    }

    const linkGroup = get(result, ['user_group_links'], [])
    const groupIds = linkGroup.map((group) => {
      return get(group, ['user_group', 'id'], '')
    })
    formData.group_id = groupIds || []
    formData.is_active = get(result, 'accounts.0.active', null)
    formData.uid = get(result, 'accounts.0.account_providers.0.auth_provider_unique_id', null)

    initialData.id = result.id
    initialData.display_name = result.display_name

    initialData.email = get(result, 'accounts.0.email', null)
    initialData.role = get(result, 'accounts.0.account_roles.0.role', null)
    initialData.group_id = get(result, 'group.id', null)
    initialData.is_active = get(result, 'accounts.0.active', null)
    initialData.uid = get(result, 'accounts.0.account_providers.0.auth_provider_unique_id', null)
  }

  const fetchByRouteId = () => {
    const id = get(ctx.root.$router.currentRoute.params, 'id', null)
    if (id) {
      formData.id = id
      $store
        .dispatch('users/fetchUserById', id)
        .then((result) => {
          prepareFormData(result)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const fetchRoleList = () => {
    $store.dispatch('users/fetchAccountRole')
  }

  const fetchGroupList = () => {
    $store.dispatch('users/fetchUserGroup')
  }

  return {
    initialData,
    formData,
    groups,
    roles,
    prepareFormData,
    fetchByRouteId,
    fetchRoleList,
    fetchGroupList,
  }
}
