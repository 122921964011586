<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>ชื่อผู้ใช้งาน (Display name) *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Name" 
                rules="required">
                <vs-input 
                  v-model="formData.display_name" 
                  class="w-6/12" 
                  name="display_name" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>อีเมล (Email) *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="email" 
                rules="required">
                <vs-input 
                  v-model.trim="formData.email"
                  :disabled="formData.id"
                  class="w-6/12"
                  name="url" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รหัสผ่าน *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="รหัสผ่าน" 
                rules="min:6">
                <div class="flex" >
                  <vs-input
                    :type="passwordFieldType"
                    name="password"
                    class="w-6/12" 
                    v-model="formData.password"
                  />
                  <feather-icon 
                    class="w-auto cursor-pointer ml-2" 
                    :icon="iconName" 
                    @click="switchVisibility"
                    svg-classes="h-6 w-6" />
                </div>
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>ยืนยันรหัสผ่าน *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ยืนยันรหัสผ่าน" 
                rules="min:6|password:@รหัสผ่าน">
                <vs-input 
                  :type="passwordFieldType"
                  name="confirm-password"
                  v-model="formData.confirmPassword"
                  class="w-6/12"
                  data-vv-as="password"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>ตำแหน่ง (Role) *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Role" 
                rules="required">
                <div>
                  <v-select
                    class="w-3/12"
                    :disabled="!isEditor"
                    v-model="formData.role"
                    :options="roles"
                    label="title"
                    :reduce="project => project.role"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    placeholder="เลือกตำแหน่ง"
                  />
                  <!-- <vs-select
                    v-model="formData.role"
                    name="Role"
                    class="selectExample"
                    icon="arrow_downward"
                    placeholder="Select Role"
                    autocomplete
                  >
                    <vs-select-item
                      v-for="(item, index) in roles"
                      :value="item.role"
                      :text="getRoleLabel(item.role)"
                      :key="index"
                    />
                  </vs-select> -->
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{
                      errors[0]
                    }}</span>
                </div>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout v-if="formData.role === 'sale' || formData.role === 'marketing'">
            <FormLabel>
              <span>กลุ่ม (Group) *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Group" 
                :rules="formData.role === 'sale' || formData.role === 'marketing' ? 'required' : ''">
                <div>
                  <v-select
                    multiple
                    taggable
                    class="w-5/12"
                    :disabled="!isEditor"
                    v-model="formData.group_id"
                    :options="groups"
                    label="group_name"
                    :reduce="group => group.id"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    placeholder="เลือกกลุ่ม"
                  />
                  <!-- <vs-select
                    v-model="formData.group_id"
                    multiple
                    :disabled="!isEditor"
                    name="Group"
                    class="selectExample"
                    icon="arrow_downward"
                    placeholder="Select Group"
                  >
                    <vs-select-item
                      v-for="(item, index) in groups"
                      :value="item.id"
                      :text="item.group_name"
                      :key="index"
                    />
                  </vs-select> -->
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{
                      errors[0]
                    }}</span>
                </div>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout v-if="formData.id && isEditor" >
            <FormLabel><span>Is active</span></FormLabel>
            <FormContent>
              <vs-switch 
                v-model="formData.is_active" 
                class="mt-2" />
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card v-if="isAdmin && isEditor" class="mb-base" title="ตั้งค่า Admin">
          <FormLayout>
            <FormLabel>
              <span>สามารถแก้ไข Seo Project ได้</span>
            </FormLabel>
            <FormContent>
              <vs-checkbox 
                v-model="formData.setting.admin.set_seo_project" 
                class="w-8/12" 
                name="set_seo" />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รับอีเมลเมื่อมีการแก้ไขโครงการ</span>
            </FormLabel>
            <FormContent>
              <vs-checkbox 
                v-model="formData.setting.admin.noti_project_update" 
                class="w-8/12" 
                name="set_seo" />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รับอีเมลเมื่อมีสร้างโปรโมชันใหม่</span>
            </FormLabel>
            <FormContent>
              <vs-checkbox 
                v-model="formData.setting.admin.noti_promotion_create" 
                class="w-8/12" 
                name="set_seo" />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รับอีเมลสรุปโปรโมชันที่รอการอนุมัติในแต่ล่ะวัน</span>
            </FormLabel>
            <FormContent>
              <vs-checkbox 
                v-model="formData.setting.admin.noti_promotion_pending_summary" 
                class="w-8/12" 
                name="set_seo" />
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card v-if="isUser && isEditor" class="mb-base" title="ตั้งค่า User">
          <FormLayout>
            <FormLabel>
              <span>รับอีเมลเมื่อมีการสร้างโครงการในกลุ่มที่ตนเองอยู่</span>
            </FormLabel>
            <FormContent>
              <vs-checkbox 
                v-model="formData.setting.user.noti_project_create" 
                class="w-8/12" 
                name="set_seo" />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รับอีเมลเมื่อมีการแก้ไขหรืออนุมัติโครงการในกลุ่มที่ตนเองอยู่</span>
            </FormLabel>
            <FormContent>
              <vs-checkbox 
                v-model="formData.setting.user.noti_project_update" 
                class="w-8/12" 
                name="set_seo" />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รับอีเมลเมื่อมีโปรโมชันที่สร้างได้รับการอนุมัติ</span>
            </FormLabel>
            <FormContent>
              <vs-checkbox 
                v-model="formData.setting.user.noti_promotion_approve" 
                class="w-8/12" 
                name="set_seo" />
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel/>
            <FormContent>
              <vs-button 
                class="w-1/3 mr-4" 
                @click="submit(context)">Submit</vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>

import { onMounted, computed, watch, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import ImageUpload from '@/components/upload/ImageUpload'
import useDatepicker from '@/use/useDatepicker'
import useAdminUserUtil from '@/use/useAdminUserUtil'
import useNotify from '@/use/useNotify'
import isEmpty from "lodash/isEmpty"

import VSelect from 'vue-select'

export default {
  name: 'AdminUserForm',
  components: {
    ImageUpload,
    ValidationObserver,
    VSelect
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const {
      initialData,
      formData,
      groups,
      roles,
      fetchByRouteId,
      fetchRoleList,
      fetchGroupList
    } = useAdminUserUtil(ctx)

    onMounted(() => {
      fetchByRouteId()
      fetchRoleList()
      fetchGroupList()
    })

    const passwordFieldType = ref('password')
    const iconName = ref('EyeIcon')

    const switchVisibility = () => {
      iconName.value = passwordFieldType.value === 'password' ? 'EyeOffIcon' : 'EyeIcon'
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
    }

    const saveItem = ({ validate }) => {
      validate().then((isValid) => {
        if (!isValid) return
        $vs.loading()
        $store
          .dispatch(`users/${formData.id ? 'updateUser' : 'registerUser'}`, { formData: {
            ...formData,
            display_name: formData.display_name.trim()
          } })
          .then(() => {
            $router.push({ name: 'admin-user' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            notifyError({ text: error.message })
          })
      })
    }

    const submit = (ctx) => {
      saveItem(ctx)
    }

    const isAdmin = computed(() => {
      return formData.role === 'super_editor' || formData.role === 'editor' 
    })

    const isUser = computed(() => {
      return formData.role === 'sale' || formData.role === 'marketing' 
    })

    watch(
      () => formData.role,
      () => {
        if (isAdmin.value) {
          formData.group_id = []
          formData.setting.user = {}
          if (isEmpty(formData.setting.admin)) {
            formData.setting.admin = Object.assign({}, initialData.setting.admin)
          }
        } else if (isUser.value) {
          formData.setting.admin = {}
          if (isEmpty(formData.setting.user)) {
            formData.setting.user = Object.assign({}, initialData.setting.user)
          }
        }
      }
    )

    const isEditor = computed(() => ctx.root.$acl.check('editor'))

    return {
      formData,
      groups,
      roles,
      submit,
      useDatepicker: useDatepicker(),
      isAdmin,
      isUser,
      switchVisibility,
      passwordFieldType,
      iconName,
      isEditor
    }
  },
}
</script>
